.sigCanvas {
  width: 100%;
  height: 286px;
  margin: 0 auto;
}
.rmdp-range:has(.reserved) {
  background-color: #8fb4f9;
}

.rmdp-range:has(.in-service) {
  background-color: #ef0a0a !important;
}

div.reserved {
  background-color: #8fb4f9;
  /* width: 20px;
  height: 20px; */
  border-radius: 50%;
}

div.in-service {
  background-color: #fff0f3 !important;
  color: #ef0a0a;
  /* width: 20px;
  height: 20px; */
  /* border-radius: 35px; */

  font-weight: 500;
  height: 100%;
  border-bottom-right-radius: 35px !important;
  border-top-right-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
  border-top-left-radius: 35px !important;
  cursor: not-allowed;
}

.un-availble {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.un-availble p {
  margin: 0;
}
