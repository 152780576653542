/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.montserrat-100 {
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Montserrat", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", serif;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
