.App {
  text-align: center;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/** scrollbar style **/
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a1919;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a1919;
}
/** end scrollbar style **/

.MuiOutlinedInput-root {
  text-align: left;
}

/* box-shadow: 4px 0px 20px 0px #0000000D; */
.MuiCard-root,
.MuiPaper-root {
  box-shadow: 0px 8px 24px 0px #4545501a !important;
  border-radius: 10px !important;
}
.MuiDataGrid-cell {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color: #201d23cc;
}
.MuiInputLabel-sizeSmall.MuiInputLabel-outlined,
.MuiInputBase-root,
input::placeholder,
textarea::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  opacity: 1 !important;
  color: #656575 !important;
}

.report-calendar .rmdp-ym .rmdp-day span {
  font-family: "Montserrat";
}
.rmdp-range-hover {
  background-color: #ef0a0a !important;
}
input::placeholder {
  font-family: "Montserrat" !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
